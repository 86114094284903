import React from "react"

import PropTypes from "prop-types"
import Tachyons from "tachyons"
import "./layout.css"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from "gatsby-image-background-slider"

const Layout = ({ children }) => {
  return (
    <main>
      {children}

      <BackgroundSlider
        query={useStaticQuery(graphql`
            query {
              backgrounds: allFile {
                nodes{
                  relativePath
                  childImageSharp {
                    fluid(maxWidth: 1500, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
        `)}
        initDelay={2}
        transition={4}
        duration={8}
      />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
