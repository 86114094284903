import React from "react"

import { graphql } from "gatsby"
import Div100vh from "react-div-100vh";
import Layout from "../components/layout"
import SEO from "../components/seo"
import FacebookIcon from "../assets/facebook-icon.svg"
import InstagramIcon from "../assets/instagram-icon.svg"

export default ({ data }) => (
  <Layout>
    <SEO />

    <Div100vh className="flex flex-column justify-between pa4-ns black-90">
      <div className="ma4" itemScope itemType="http://schema.org/LocalBusiness">
        <h1 className="fw6 db mv2" itemProp="name">{data.site.siteMetadata.title}</h1>

        <div className="mv2">
          <address className="db" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
            <span itemProp="streetAddress">3 Place de l’Auditoire </span>
            <span itemProp="postalCode">29870 </span>
            <span itemProp="addressLocality">Lannilis</span>
          </address>

          <div itemProp="openingHours" dateTime="Tu-Sa 9:30-18:00">Mardi–Samedi / 9:30–18:00</div>
        </div>

        <div className="mv2">
          <a className="link dim color-inherit" itemProp="email" href="mailto:Le Salon<lesalonlannilis@gmail.com>">lesalonlannilis@gmail.com </a><br/>
          <a className="link dim color-inherit" itemProp="telephone" href="tel:0298041566">02 98 04 15 66</a>
        </div>
      </div>

      <div className="ma4 tr">
        <a className="dib mr2" style={{width: "1.1rem", height: "1.1rem"}} href={"https://www.instagram.com/" + data.site.siteMetadata.instagramUsername}><InstagramIcon className="o-80 w-100 h-100"/></a>
        <a className="dib" style={{width: "1.1rem", height: "1.1rem"}} href={"https://www.facebook.com/" + data.site.siteMetadata.facebookUsername}><FacebookIcon className="o-80 w-100 h-100"/></a>
      </div>
    </Div100vh>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        instagramUsername,
        facebookUsername
      }
    }
  }
`
